import ApplicationController from "javascript/controllers/application_controller";
import Rails from '@rails/ujs';
import Sortable from "javascript/vendor/sortablejs/Sortable";

export default class extends ApplicationController {
  static targets = [
    'form',
    'reloadInput',
    'contentLoader',
    'container',
    'productsContainer',
    'newProductLink',
  ];

  connect() {
    Array.prototype.forEach.call(this.productsContainerTargets, (productContainerElement, index) => {
      productContainerElement.addEventListener('click', this.onProductContainerElementClick.bind(this, index));
    });

    Array.prototype.forEach.call(this.containerTargets, (containerElement, index) => {
      this.createSortableFromDOMNode(containerElement, {
        groupName: containerElement.getAttribute('data-group'),
      });
    });

    this.categoryExpandedObserver = new MutationObserver((mutationList) => {
      if (mutationList.find(x => x.attributeName === 'aria-expanded')) {
        this.onCategoryExpandedStateChanged();
      }
    });

    this.categoryExpandedObserver.observe(this.element, {
      attributes: true,
      subtree: true,
    });
  }

  disconnect() {
    this.categoryExpandedObserver.disconnect();
  }

  submitForm(reload = false) {
    this.reloadInputTarget.value = String(reload);
    Rails.fire(this.formTarget, 'submit');

    if (reload) {
      this.toggleFullscreenLoader(true);
    }
  }

  onFormSubmitSuccess(e) {
    if (this.reloadInputTarget.value === "true") {
      const contentLoaderInstance = this.application.getControllerForElementAndIdentifier(this.contentLoaderTarget, 'content-loader');
      if (contentLoaderInstance) {
        contentLoaderInstance.replaceBody(e);
      }

      this.toggleFullscreenLoader();
    }
  }

  onFormSubmitError() {
    window.alert(this.formTarget.getAttribute('data-error-message'));

    this.toggleFullscreenLoader();
  }

  toggleFullscreenLoader(show = false) {
    const fullScreenLoadingIndicatorDOMNode = this.window.document.querySelector('[data-controller="fullscreen-loading-indicator"]');
    fullScreenLoadingIndicatorDOMNode.setAttribute('data-fullscreen-loading-indicator-should-show', show);
  }

  sortMenu(sortBy) {
    switch (sortBy) {
      case "LowHigh":
        this.sortProductsByPrice("LowHigh");
        break;
      case "HighLow":
        this.sortProductsByPrice("HighLow");
        break;
      case "AZ":
        this.sortProductsAndCategoriesByName("AZ");
        break;
      case "ZA":
        this.sortProductsAndCategoriesByName("ZA");
        break;
    }
  }

  updateProductPositionAfterSort(productList) {
    productList.forEach((productDOMNode, index) => {
      const itemID = productDOMNode.getAttribute('data-id');
      const positionInputDOMNode = productDOMNode.querySelector(`input[name="products[${itemID}][position]"]`);
      positionInputDOMNode.value = index;
    });
  }

  sortListByName(list, sortFunction) {
    let returnInt = 1;

    if (sortFunction == "ZA") {
      returnInt = -1;
    }

    list.sort((a, b) => {
      return returnInt * (a.getAttribute('data-name').toLowerCase().localeCompare(b.getAttribute('data-name').toLowerCase()));
    });
  }

  sortProductsByPrice(sortFunction) {
    Array.prototype.forEach.call(this.element.querySelectorAll('[data-type="category"]'), (childNode) => {
      const productDOMNodes = childNode.querySelectorAll('[data-type~="product"]');
      const productList = Array.prototype.slice.call(productDOMNodes, 0);
      let returnInt = 1;

      if (sortFunction == "HighLow") {
        returnInt = -1;
      }

      productList.sort((a, b) => {
        const a_price = parseInt(a.getAttribute('data-price'), 10);
        const b_price = parseInt(b.getAttribute('data-price'), 10);
        if (a_price > b_price) { return returnInt; }
        if (a_price < b_price) { return returnInt * -1; }
        return 0;
      });

      this.updateProductPositionAfterSort(productList);
    });

    this.submitForm(true);
  }

  sortProductsAndCategoriesByName(sortFunction) {
    const categoryDOMNodes = this.element.querySelectorAll('[data-type="category"]');
    const categoryList = Array.prototype.slice.call(categoryDOMNodes, 0);

    this.sortListByName(categoryList, sortFunction);

    categoryList.forEach((categoryDOMNode, index) => {
      const itemID = categoryDOMNode.getAttribute('data-id');
      const productDOMNodes = categoryDOMNode.querySelectorAll('[data-type~="product"]');
      const productList = Array.prototype.slice.call(productDOMNodes, 0);

      if (itemID) {
        const positionInputDOMNode = categoryDOMNode.querySelector(`input[name="categories[${itemID}][position_in_menu]"]`);
        positionInputDOMNode.value = index;
      }

      this.sortListByName(productList, sortFunction);

      this.updateProductPositionAfterSort(productList);
    });

    this.submitForm(true);
  }

  onCategoryExpandedStateChanged() {
    Array.prototype.forEach.call(this.element.querySelectorAll('[data-type="category"]'), (childNode) => {
      const categoryID = childNode.getAttribute('data-id');
      if (categoryID) {
        const isExpanded = (childNode.getAttribute('aria-expanded') === 'true');
        const categoryInputDOMNode = childNode.querySelector(`input[name="categories[${categoryID}][is_collapsed]"]`);
        categoryInputDOMNode.value = !isExpanded;
      }
    });

    this.submitForm();
  }

  onProductContainerElementClick(index, e) {
    if (!e.target.hasChildNodes() && this.newProductLinkTargets[index]) {
      this.newProductLinkTargets[index].click();
    }
  }

  updateItemsPositionsAndCategoryIDs(itemType, containerDOMNode) {
    Array.prototype.forEach.call(containerDOMNode.childNodes, (childNode, index) => {
      const itemID = childNode.getAttribute('data-id');

      if (itemType === 'product') {
        const positionInputDOMNode = childNode.querySelector(`input[name="products[${itemID}][position]"]`);
        const categoryInputDOMNode = childNode.querySelector(`input[name="products[${itemID}][station_product_category_id]"]`);

        positionInputDOMNode.value = index;
        categoryInputDOMNode.value = childNode.closest('[data-type="category"]').getAttribute('data-id');
      } else {
        const positionInputDOMNode = childNode.querySelector(`input[name="categories[${itemID}][position_in_menu]"]`);
        positionInputDOMNode.value = index;
      }
    });
  }

  removeDraggingOverCategoryListClassNames() {
    const categoryDOMNodes = this.element.querySelectorAll('[data-type="category"]');
    Array.prototype.forEach.call(categoryDOMNodes, (categoryDOMNode) => categoryDOMNode.classList.remove('sortable-product-list-dragging-over-list'));
  }

  removeBodyItemDraggingClassName() {
    this.window.document.body.classList.remove('sortable-product-list-is-dragging-anything');
    this.window.document.body.classList.remove('sortable-product-list-is-dragging-product');
    this.window.document.body.classList.remove('sortable-product-list-is-dragging-category');
  }

  createSortableFromDOMNode(DOMNode, options) {
    new Sortable(DOMNode, {
      animation: 60,
      direction: 'vertical',
      delay: 100,
      delayOnTouchOnly: true,
      emptyInsertThreshold: 20,
      fallbackOnBody: true,
      invertSwap: true,

      group: {
        name: options.groupName,
      },

      onChoose: (e) => {
        const itemType = e.item.getAttribute('data-type');

        this.window.document.body.classList.add('sortable-product-list-is-dragging-anything');
        if (itemType === 'product') {
          this.window.document.body.classList.add('sortable-product-list-is-dragging-product');
        } else {
          this.window.document.body.classList.add('sortable-product-list-is-dragging-category');
        }
      },

      onUnchoose: () => {
        this.removeBodyItemDraggingClassName();
      },

      onMove: (e) => {
        const toCategoryDOMNode = e.to.closest('[data-type="category"]');

        this.removeDraggingOverCategoryListClassNames();

        if (toCategoryDOMNode) {
          toCategoryDOMNode.classList.add('sortable-product-list-dragging-over-list');
        }
      },

      onEnd: (e) => {
        const toCategoryDOMNode = e.to.closest('[data-type="category"]');
        const itemType = e.item.getAttribute('data-type');

        this.removeDraggingOverCategoryListClassNames();

        this.updateItemsPositionsAndCategoryIDs(itemType, e.to);
        this.updateItemsPositionsAndCategoryIDs(itemType, e.from);

        if (toCategoryDOMNode && e.from !== e.to) {
          toCategoryDOMNode.classList.add('sortable-product-list-chosen-list');

          window.setTimeout(() => {
            toCategoryDOMNode.classList.remove('sortable-product-list-chosen-list');
          }, 300);
        }

        this.removeBodyItemDraggingClassName();

        this.submitForm();
      },
    });
  }

}
