import ApplicationController from "javascript/controllers/application_controller"

export default class extends ApplicationController {

  connect() {
    this.bodyElement = this.document.body;
    this.bodyShouldShowClassName = 'main-navigation-should-show';
  }

  show() {
    this.bodyElement.classList.add(this.bodyShouldShowClassName);
  }

  hide() {
    this.bodyElement.classList.remove(this.bodyShouldShowClassName);
  }

  onTriggerButtonClick() {
    if (this.bodyElement.classList.contains(this.bodyShouldShowClassName)) {
      this.hide();
    } else {
      this.show();
    }
  }

  onCloseButtonClick() {
    this.hide();
  }

  onBackdropClick() {
    this.hide();
  }
}
