import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [
    'sortableProductList',
  ];

  sortMenu(event) {
    const sortBy = event.currentTarget.getAttribute('data-sort-by');
    const sortableProductListInstance = this.application.getControllerForElementAndIdentifier(this.sortableProductListTarget, 'sortable-product-list');
    sortableProductListInstance.sortMenu(sortBy);
  }

}
