import ApplicationController from "javascript/controllers/application_controller"
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  get shouldShow() {
    return false || (this.data.get('shouldShow') === 'true');
  }


  show() {
    const bodyElement = this.document.body;
    bodyElement.classList.add('fullscreen-loading-indicator-is-showing');
  }

  hide() {
    const bodyElement = this.document.body;
    bodyElement.classList.remove('fullscreen-loading-indicator-is-showing');
  }

  connect() {
    this.observer = new MutationObserver(mutationList => {
      if (mutationList.find(x => x.attributeName === 'data-fullscreen-loading-indicator-should-show')) {
        if (this.shouldShow === true) {
          this.show();
        }
        else {
          this.hide();
        }
      }
    });

    this.observer.observe(this.element, { attributes: true });
  }

  disconnect() {
    this.observer.disconnect();
  }
}