import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  confirm(e) {
    const result = window.confirm(this.element.getAttribute('data-confirm-operation-message'));
    if (!result) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
}
