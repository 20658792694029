import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [
    'iframe',
  ];

  onIframeLoaded() {
    this.element.style.height = this.iframeTarget.contentWindow.document.body.scrollHeight + 'px';
  }

}
