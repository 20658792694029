import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'javascript/analytics/sentry';

import Turbolinks from 'turbolinks';
import Rails from '@rails/ujs';

import 'javascript/compatibility/polyfills';
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

Turbolinks.start();
Rails.start();

const application = Application.start();

const sharedContext = require.context("javascript/controllers/shared", true, /.js$/);
application.load(definitionsFromContext(sharedContext));

const context = require.context("javascript/controllers/hawker_portal", true, /.js$/);
application.load(definitionsFromContext(context));
