import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {

  static targets = [
    'submitButton',
  ];

  connect() {
    this.element.classList.add(this.element.classList[0] + '--auto-submit');
  }

  onVendorSelectionChanged() {
    this.submitButtonTarget.click();
  }
}
