import ApplicationController from "javascript/controllers/application_controller"

export default class extends ApplicationController {
  show() {
    this.window.document.querySelector('.fullscreen-loading-indicator').setAttribute('data-fullscreen-loading-indicator-should-show', 'true');
  }

  hide() {
    this.window.document.querySelector('.fullscreen-loading-indicator').setAttribute('data-fullscreen-loading-indicator-should-show', 'false');
  }
}
